// Generated by Framer (d9af232)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["cW6nnLEzP"];

const serializationHash = "framer-1qw1B"

const variantClassNames = {cW6nnLEzP: "framer-v-1ufjx3b"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "cW6nnLEzP", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ufjx3b", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"cW6nnLEzP"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1v2671k"} data-framer-name={"Line 12"} layout={"position"} layoutDependency={layoutDependency} layoutId={"F2Mrga5cw"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 3 175\"><path d=\"M 1 1.5 L 1 174\" fill=\"transparent\" stroke-width=\"2\" stroke=\"rgb(0,0,0)\" stroke-miterlimit=\"10\" stroke-dasharray=\"8,8\"></path></svg>"} svgContentId={10422224301} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1qw1B.framer-1jrs6tv, .framer-1qw1B .framer-1jrs6tv { display: block; }", ".framer-1qw1B.framer-1ufjx3b { height: 175px; overflow: hidden; position: relative; width: 3px; }", ".framer-1qw1B .framer-1v2671k { flex: none; height: 175px; left: calc(33.33333333333336% - 3px / 2); position: absolute; top: calc(49.71428571428574% - 175px / 2); width: 3px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 175
 * @framerIntrinsicWidth 3
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramermI9affEhq: React.ComponentType<Props> = withCSS(Component, css, "framer-1qw1B") as typeof Component;
export default FramermI9affEhq;

FramermI9affEhq.displayName = "Line 12";

FramermI9affEhq.defaultProps = {height: 175, width: 3};

addFonts(FramermI9affEhq, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})